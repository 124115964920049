import 'isomorphic-fetch'
import Cookies from 'js-cookie';
import {TOKEN_BASIC} from '../constants/config'

function getTokenFromCookie(){
  let token
  try{
    //console.log(Cookies.getJSON('account'))
    token = Cookies.getJSON("account").token
  }
  catch(e){
    //console.log(e)
  }
  return token
}

function callGET(endpoint) {
  //console.log("callGET")
  let token = getTokenFromCookie();
  //console.log("token", token);
  //console.log("endpoint:", endpoint)
  //console.log("token:", token)
  return fetch(endpoint, {
    
    method: "GET", headers: { 
      "Content-Type": "application/json", 
      "Authorization": "Bearer " + token
    }})
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    //console.log("json", json)
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json;
  })
  .then(
    response => (response)
  )
}

/*function callPOST(endpoint, request) {
  console.log("callPOST", endpoint, request)
  return fetch(endpoint, {
                          method: "POST", headers: { 
                            "Content-Type": "application/json", 
                            "Authorization": "Bearer " + getTokenFromCookie()
                          }, 
                          body: JSON.stringify(request.payload)
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    const camelizedJson = camelizeKeys(json)
    return Object.assign({},normalize(camelizedJson, schema),{})
  })
  .then(
    response => ({response})
  )
}*/

const tokenBasic = TOKEN_BASIC;

function callLoginPOST(endpoint, request) {
  //console.log("callPOST", request)
  return fetch(endpoint, {
                          method: "POST", 
                          headers: {  "Content-Type": "application/json"/*, 
                                      /*"Authorization": 'Basic ' + tokenBasic*/}, 
                          body: JSON.stringify(request.payload)
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response }))
  ).then(({ json, response }) => {
    //console.log(response)
    if (!response.ok) {
      return Promise.reject(json)
    }
    //console.log("json: ",json)
    return json;
    //const camelizedJson = camelizeKeys(json)
    //return Object.assign({},normalize(camelizedJson, schema),{})
  })
  .then(
    response => ({response})
  )
}

function callPOST(endpoint, request) {
  //console.log("callPOST", endpoint, request)
  
  let token = getTokenFromCookie();
  return fetch(endpoint, {
                          method: "POST", headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                          },
                          body: request
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

function callLoginGET(endpoint) {
  console.log("callLoginGET")
  return fetch(endpoint, {
                          method: "GET", 
                          headers: {  "Content-Type": "application/x-www-form-urlencoded", 
                                      "Authorization": 'Basic ' + tokenBasic}
                        }
              )
  .then(response =>
    response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }
    return json
  })
  .then(
    response => ({response})
  )
}

export const getCall = endpoint => callGET(endpoint)
export const postCall = (endpoint, payload) => callPOST(endpoint, payload)
export const loginPOST = (endpoint, payload) => callLoginPOST(endpoint, payload)
export const loginGET = (endpoint) => callLoginGET(endpoint)
