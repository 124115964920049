export const NOT_LOGGED = 'NOT_LOGGED'
export const LOGGED = 'LOGGED'

export const MENU_LOADING = 'MENU_LOADING'
export const MENU_SUCCESS = 'MENU_SUCCESS'
export const MENU_ERROR = 'MENU_ERROR'

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

export const LOGOUT_LOADING = 'LOGOUT_LOADING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'


export const INTERMEDIARIOS_LOADING = 'INTERMEDIARIOS_LOADING'
export const INTERMEDIARIOS_ERROR = 'INTERMEDIARIOS_ERROR'
export const INTERMEDIARIOS_SUCCESS = 'INTERMEDIARIOS_SUCCESS'

export const INTERMEDIARIOS_SEARCH_LOADING = 'INTERMEDIARIOS_SEARCH_LOADING'
export const INTERMEDIARIOS_SEARCH_ERROR = 'INTERMEDIARIOS_SEARCH_ERROR'
export const INTERMEDIARIOS_SEARCH_SUCCESS = 'INTERMEDIARIOS_SEARCH_SUCCESS'


export const IMPERSONALIZAR_LOADING = 'IMPERSONALIZAR_LOADING'
export const IMPERSONALIZAR_ERROR = 'IMPERSONALIZAR_ERROR'
export const IMPERSONALIZAR_SUCCESS = 'IMPERSONALIZAR_SUCCESS'

export const CHECK_TOKEN = 'CHECK_TOKEN'

export const ROLE_BACKOFFICE = 'ROLE_BACKOFFICE'
export const ROLE_TELEMARKETING = 'ROLE_TELEMARKETING'

export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'