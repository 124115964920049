
import LoginCmp from '../components/LoginCmp.js';

const { connect } = require('react-redux');

const { login, checkToken  } = require('../actions/loginAction')


const mapStateToProps = (state) => {
    return{
      loginResponse: state.loginReducer.loginResponse,
      loginStatus: state.loginReducer.loginStatus,
      impersonalizarStatus: state.intermediarioReducer.impersonalizarStatus
    }
};

const mapDispatchToProps = dispatch => ({
  login: (payload) => {
    dispatch(login(payload));
  },
  checkToken: () => {
    dispatch(checkToken())
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(LoginCmp);
