import React, { Component } from 'react'
import * as CONSTANTS from '../constants/constants'
import MenuCnt from '../containers/MenuCnt'
import LoginCnt from '../containers/LoginCnt'
// import PageEmbeddedCmp from './PageEmbeddedCmp'
import Cookies from 'js-cookie';
// import {URL_LOGIN} from '../constants/config'

export default class AppCoreCmp extends Component {
  
  constructor(props){
    var options = {expires: new Date(new Date().getTime() + 30*1000 ), domain: '.colonseguros.com.ar'}
    Cookies.set('portalProductores', 'donotredirect', options);
    window.parent.onmessage = function(event) {
      //console.log("event 1", event)
      if(event.data.message==="login"){
        props.getMenu();
        props.getIntermediario();
      }
      if(event.data.message==="menuPermissions"){
        //console.log("messages", event.data.value)
        localStorage.setItem("menuPermissions", event.data.value)
      }  
    };
    

    super(props);
    this.state = {
      menu: undefined,
      status: CONSTANTS.NOT_LOGGED
    }
    props.getMenu()
    props.getIntermediario();
  }

  componentDidUpdate(prevProps){
    /*
    if( this.props.menu.menu === undefined ){
      this.props.getMenu()
    }*/
    this.ifStatusChange(prevProps)    
  }

  ifStatusChange(prevProps){
    if(prevProps.menu !== this.props.menu){
      
      this.setState({menu: this.props.menu})
    }
    //console.log("app this.props: ",this.props)
  }

  onClickProps(){

  }

  getMenu(){
    this.props.getMenu()
  }

  render() {
    // let urlHome = URL_LOGIN
    let menu = null
    let token 
    let account = Cookies.get("account")
    
    if(account)
      account = JSON.parse(decodeURI(Cookies.get("account")))
    //console.log(account)
    if(account)
      token = account.token
      //console.log("account render", Cookies.getJSON("account"))
    if (token && this.state.menu !== undefined && this.state.menu.menu !== undefined){
      menu = <MenuCnt/>
    }
    else{
      // menu = <PageEmbeddedCmp url={urlHome} openMenu={false} showMenu={false}/>
      if(!token || (this.props.menu && this.props.menu.error==="invalid_token"))
        menu = <LoginCnt/>
    }
  
    return (
        <div>
            { menu }
      </div>
    );
  }
}