import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './routes/Root'
import configureStore from './store/configureStore'
import rootSaga from './sagas/sagas'
import 'semantic-ui-css/semantic.min.css'
import * as URLMAPPING from './constants/UrlMapping';

const store = configureStore()
store.runSaga(rootSaga)

render(
  <Router basename={URLMAPPING.LOGIN_ROOT}>
    <Root store={store}  basename={URLMAPPING.LOGIN_ROOT} />
  </Router>,
  document.getElementById('root')
)
