/* eslint-disable no-constant-condition */
import { takeEvery, takeLatest, /*take,*/ put, call, /*fork,*/ /*select,*/ all, delay  } from 'redux-saga/effects'
import { loginServices } from '../services'
import Cookies from 'js-cookie';
import _ from 'lodash'
import * as CONSTANTS from '../constants/constants'
import { API_URL_SERVICE, API_URL_FRONT/*, API_URL*/ } from '../constants/config';

export function* checkToken(payload) {
  try {
    if(window.location.href === API_URL_FRONT)
      return ;

    let token = Cookies.getJSON("account").token
    
    //const result = yield call(loginServices.postCall, API_URL_SERVICE + '/menu_provider/api/check-token', token);
    const result = yield call(loginServices.loginGET, API_URL_SERVICE + '/oauth2/oauth/check_token?token=' + token);  
    
    if(result.error !== "invalid_token")
      yield put({type: CONSTANTS.LOGGED, userData: result});
    else  
      window.location.href = API_URL_FRONT;
  } catch (e) {
    console.log("CHECK TOKEN ERROR - TOKEN INVALID")
    Cookies.remove("account")
    window.location.href = API_URL_FRONT;
    
  }
}

export function* getMenu(){
  try {
    const result = yield call(loginServices.getCall, API_URL_SERVICE +'/menu_provider/api/menu/portal', null);
    //const result = yield call(loginServices.getCall, 'https://septest.colonseguros.com.ar' +'/menu_provider/api/menu/portal', null);
    yield put({type: CONSTANTS.MENU_SUCCESS, response: result});
  } catch (e) {
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.MENU_ERROR), message: e.message});
  }
}

function isProducer(){
  return _.find(Cookies.getJSON("account").userRoles, (item)=>{return item.authority === "ROLE_PRODUCER"}) !== undefined
}

/* post secure */
export function* getIntermediarios(payload) {
  try {
    let cuit = Cookies.getJSON("account").cuit;
    //let url = isProducer() ? `${API_URL_SERVICE}/gestor-cotizador-emisor-services/api/gaus/intermediarios` : `${API_URL_SERVICE}/gestor-cotizador-emisor-services/api/gaus/intermediarios/admproducer`
    // let baseUrl = 'http://localhost:8010'
    let baseUrl = API_URL_SERVICE +'/menu_provider';
    let url = isProducer() ? `${baseUrl}/api/intermediarios` : `${baseUrl}/api/intermediarios/admproducer`
    const result = yield call(loginServices.postCall,url, JSON.stringify({cuit: Number.parseInt(cuit)}));
    //const result = yield call(loginServices.postCall,`https://septest.colonseguros.com.ar/gestor-cotizador-emisor-services/api/gaus/intermediarios`, cuit);
    yield put({type: CONSTANTS.INTERMEDIARIOS_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.INTERMEDIARIOS_ERROR), message: e.message});
  }
}

/* post secure */
export function* getIntermediariosSearch(payload) {
  try {
    //const result = yield call(loginServices.postCall,`${API_URL_SERVICE}/gestor-cotizador-emisor-services/api/gaus/intermediarios/backoffice`, payload.payload.search);
    //let baseUrl = 'http://localhost:8010'
    let baseUrl = API_URL_SERVICE +'/menu_provider';
    const result = yield call(loginServices.postCall,`${baseUrl}/api/intermediarios/backoffice`, JSON.stringify({search: payload.payload.search}));
    //const result = yield call(loginServices.postCall,`https://septest.colonseguros.com.ar/gestor-cotizador-emisor-services/api/gaus/intermediarios/backoffice`, payload.payload.search);
    yield put({type: CONSTANTS.INTERMEDIARIOS_SEARCH_SUCCESS, userData: result});
  } catch (e) {
    //console.log("error: " ,(e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.INTERMEDIARIOS_SEARCH_ERROR))
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.INTERMEDIARIOS_SEARCH_ERROR), message: e.message});  
  }
}

export function* impersonalizar(payload) {
  try {
    //const result = yield call(loginServices.postCall,`${API_URL_SERVICE}/gestor-cotizador-emisor-services/api/gaus/intermediarios/impersonalizar`, JSON.stringify(payload.payload));
    //let baseUrl = 'http://localhost:8010'
    let baseUrl = API_URL_SERVICE +'/menu_provider';
    const result = yield call(loginServices.postCall,`${baseUrl}/api/intermediarios/impersonalizar`, JSON.stringify(payload.payload));
    //const result = yield call(loginServices.postCall,`https://septest.colonseguros.com.ar/gestor-cotizador-emisor-services/api/gaus/intermediarios/impersonalizar`, JSON.stringify(payload.payload));
    yield put({type: CONSTANTS.IMPERSONALIZAR_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.IMPERSONALIZAR_ERROR), message: e.message});
  }
}

export function* logout(){
  try {
    //const result = yield call(loginServices.getCall, 'http://localhost:8090' + '/api/login/logout', null);
    //let baseUrl = 'http://localhost:8010'
    let baseUrl = API_URL_SERVICE +'/menu_provider';
    //const result = yield call(loginServices.getCall, API_URL_SERVICE + '/ov_services/api/login/logout', null);
    const result = yield call(loginServices.getCall, `${baseUrl}/security/logout`, null);
    yield put({type: CONSTANTS.LOGOUT_SUCCESS, response: result});
  } catch (e) {
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.LOGOUT_ERROR), message: e.message});
  }
}

export function* verifyTokenValidity(){
  //yield redirectToLogin()
}


export function* login(payload){
  try {
    let baseUrl = API_URL_SERVICE +'/menu_provider';
    const result = yield call(loginServices.loginPOST, `${baseUrl}/security/check-token`, payload);
    if(!result.response){
      const shouldContinue = yield call(showConfirmationModal);
      if (shouldContinue) {
        const loginResult = yield call(loginServices.loginPOST, `${baseUrl}/security/login`, payload);
        yield put({ type: CONSTANTS.LOGIN_SUCCESS, response: loginResult });
      }else{
        yield put({type: CONSTANTS.CHECK_TOKEN});
      }
    }else{
      yield put({type: CONSTANTS.LOGIN_SUCCESS, response: result});
    }
  } catch (e) {
    yield put({type: (e.error === "invalid_token" ? CONSTANTS.CHECK_TOKEN : CONSTANTS.LOGIN_ERROR), message: e.message});
  }
}


  
export function* showConfirmationModal() {
  const shouldContinue = window.confirm('Lo siento, pero parece que ya tienes una sesión abierta en tu cuenta.\n\n¿Deseas iniciar una nueva sesión y cerrar tu sesión previa?');
  return shouldContinue;
}

export default function* root() {

  yield takeEvery(CONSTANTS.MENU_LOADING, getMenu)
  yield takeEvery(CONSTANTS.LOGIN_LOADING, login)
  yield takeEvery(CONSTANTS.VALIDATE_TOKEN, verifyTokenValidity)
  yield takeEvery(CONSTANTS.LOGOUT_LOADING, logout)
  yield takeEvery(CONSTANTS.INTERMEDIARIOS_LOADING, getIntermediarios)
  yield takeLatest(CONSTANTS.INTERMEDIARIOS_SEARCH_LOADING, getIntermediariosSearch)
  yield takeEvery(CONSTANTS.IMPERSONALIZAR_LOADING, impersonalizar)
  yield takeEvery(CONSTANTS.CHECK_TOKEN, checkToken)
  yield all([])
}

