
import MenuCmp from '../components/MenuCmp.js';
//import { stat } from 'fs';

const { connect } = require('react-redux');

const { logout, getIntermediariosSearch, impersonalizar, checkToken  } = require('../actions/loginAction')


const mapStateToProps = (state) => {
    return{
      menu: state.homeReducer.menu,
      status: state.homeReducer.status,
      intermediarios: state.intermediarioReducer.intermediarios,
      intermediariosSearch: state.intermediarioReducer.intermediariosSearch,
      intermediariosSearchStatus: state.intermediarioReducer.intermediariosSearchStatus,
      impersonalizado: state.intermediarioReducer.impersonalizado,
      impersonalizarStatus: state.intermediarioReducer.impersonalizarStatus
    }
};

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  },
  getIntermediariosSearch: (payload) =>{
    dispatch(getIntermediariosSearch(payload));
  }, 
  impersonalizar: (payload) =>{
    dispatch(impersonalizar(payload))
  }, 
  checkToken: () => {
    dispatch(checkToken())
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(MenuCmp);
