import * as CONSTANTS from '../constants/constants'

export function getMenu() {
    return {
        type: CONSTANTS.MENU_LOADING
    }
}

export function getIntermediario(payload) {
    return {
        type: CONSTANTS.INTERMEDIARIOS_LOADING,
        payload: payload
    }
}

export function getIntermediariosSearch(payload) {
    return {
        type: CONSTANTS.INTERMEDIARIOS_SEARCH_LOADING,
        payload: payload
    }
}

export function impersonalizar(payload) {
    return {
        type: CONSTANTS.IMPERSONALIZAR_LOADING,
        payload: payload
    }
}

export function logout() {
    return {
        type: CONSTANTS.LOGOUT_LOADING
    }
}

export function checkToken(){
    return {
        type: CONSTANTS.CHECK_TOKEN
    }
}

export function login(payload) {
    return {
        type: CONSTANTS.LOGIN_LOADING,
        payload: payload
    }
}   

export const actions = {
    getMenu,
    getIntermediario,
    getIntermediariosSearch,
    logout, 
    checkToken
};