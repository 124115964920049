
import AppCoreCmp from '../components/AppCoreCmp.js';

const { connect } = require('react-redux');

const { getMenu, getIntermediario } = require('../actions/loginAction')

const mapStateToProps = (state) => {
    return{
      menu: state.homeReducer.menu,
      status: state.homeReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  getMenu: () => {
    dispatch(getMenu());
  },
  getIntermediario: () => {
    dispatch(getIntermediario());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(AppCoreCmp);
