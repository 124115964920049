import merge from 'lodash/merge'
import { combineReducers } from 'redux'
import * as CONSTANTS from '../constants/constants'

const homeState = {}

let mapping = {
  [CONSTANTS.MENU_SUCCESS]: "menu",
  [CONSTANTS.INTERMEDIARIOS_SUCCESS]: "intermediarios",
  [CONSTANTS.INTERMEDIARIOS_SEARCH_SUCCESS]: "intermediariosSearch",
  [CONSTANTS.INTERMEDIARIOS_SEARCH_LOADING]: "intermediariosSearchStatus",
  [CONSTANTS.IMPERSONALIZAR_SUCCESS]: "impersonalizado",
  [CONSTANTS.IMPERSONALIZAR_LOADING]: "impersonalizarStatus"
}

const homeReducer = (state = { homeState }, action) => {
  if(action.type !== CONSTANTS.MENU_SUCCESS)
    return state;

  //console.log("action.response", action.response)
  if (  action.response ){
    let newState = { [mapping[action.type]] : action.response }
    return merge([], state, newState)
  }
  return merge({}, state, action.payload)
}

const intermediarioReducer = (state = { homeState }, action) => {
  if(action.type !== CONSTANTS.INTERMEDIARIOS_SUCCESS && 
    action.type !==  CONSTANTS.INTERMEDIARIOS_SEARCH_SUCCESS &&
    action.type !==  CONSTANTS.INTERMEDIARIOS_SEARCH_LOADING && 
    action.type !==  CONSTANTS.IMPERSONALIZAR_SUCCESS &&
    action.type !==  CONSTANTS.IMPERSONALIZAR_LOADING
    )
    return state;

  //console.log(action.type)
  if(action.type.endsWith('_LOADING')){
    let newState = { [mapping[action.type]] : "FETCHING" }
    return merge([], state, newState)
  }

  if(action.type === CONSTANTS.INTERMEDIARIOS_SEARCH_SUCCESS){
    let newState = state;
    newState[mapping[action.type]] = action.userData.response
    newState["intermediariosSearchStatus"] = "SUCCESS"
    return merge([], state, newState)
  }


  if(action.type === CONSTANTS.IMPERSONALIZAR_SUCCESS){
    let newState = state;
    newState[mapping[action.type]] = action.userData.response
    newState["impersonalizarStatus"] = "SUCCESS"
    return merge([], state, newState)
  }

  //console.log("action.response", action.response)
  //if (  action.response ){
    //console.log("action intermediario", action.userData.response)
    let newState = { [mapping[action.type]] : action.userData.response }
    return merge([], state, newState)
  //}
  //return merge({}, state, action.payload)
}



const loginReducer = (state = { homeState }, action) => {
    if(action.type !== CONSTANTS.LOGIN_SUCCESS && 
      action.type !==  CONSTANTS.LOGIN_LOADING &&
      action.type !==  CONSTANTS.LOGIN_ERROR
      )
      return state;
  
    // console.log("action", action)
    if(action.type.endsWith('_LOADING')){
      let newState =  {"loginStatus" : "FETCHING" }
      return merge([], state, newState)
    }
  
    if(action.type.endsWith('_ERROR')){
      let newState = {"loginStatus": "ERROR"}
      //console.log(newState)
      return merge([], state, newState)
    }
  
    if(action.type === CONSTANTS.LOGIN_SUCCESS){
      let newState = state;
      newState["loginResponse"] = action.response.response
      newState["loginStatus"] = "SUCCESS"
      return merge([], state, newState)
    }
  
    let newState = { [mapping[action.type]] : action.response }
    return merge([], state, newState)
  }


const rootReducer = combineReducers({
  homeReducer,
  loginReducer,
  intermediarioReducer
})

export default rootReducer
