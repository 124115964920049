import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import createSagaMiddleware, { END } from 'redux-saga'
import sagaMonitor from '@redux-saga/simple-saga-monitor'

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(sagaMiddleware),
        (fn) => fn
    )
  )
  
  store.runSaga = sagaMiddleware.run

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
  store.close = () => store.dispatch(END)
  return store
}

export default configureStore
