import React, { Component } from 'react'

export default class PageEmbeddedCmp extends Component {

    constructor(props){
        super(props)
        this.state = {
            url: props.url,
            openMenu: props.openMenu
        }
    }

    componentDidUpdate(prevProps){
        this.ifStatusChange(prevProps)    
    }    

    ifStatusChange(prevProps){

        if(prevProps.url !== this.props.url && this.props.url !== this.state.url){
            //console.log("prevProps.url: ",prevProps.url)
            //console.log("this props", this.props.url)
            this.setState({url: this.props.url})
        }

        if(prevProps.openMenu !== this.props.openMenu){
            this.setState({openMenu: this.props.openMenu})
        }

        //console.log("embedded this props: ",this.props)
    }

    renderPage(){
        if(this.props.reload === true)
            return null
        return      <object className={"page-right " + (this.state.openMenu ? "embed-responsive-item" : (this.props.showMenu !== false ? "embed-responsive-item-collapsed-menu" : ""))  } 
                        height="100%" 
                        data={this.state.url}
                        type="text/html" typemustmatch="true">
                    </object> 
    }

    render (){
        return (
                
                <div>
                        {this.renderPage()}
                </div>
        )
    }
}
