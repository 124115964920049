import React, { Component } from 'react'
import { Button, Form, Grid, Segment } from 'semantic-ui-react'
import {COOKIE_DNS, COOKIE_EXPIRE_TIME} from '../constants/config'
import  '../styles/main.scss'
import image from '../assets/images/login-img.jpg';
import logo from "../assets/images/login-logo10.png";
import Cookies from 'js-cookie';

export default class LoginCmp extends Component {
  
    constructor(props){
        super(props)
        this.listener = null

        this.state = {
            hidden: true,
            submitted: false,
            components: {
                username: {value: '', name: 'username', label: 'USUARIO', error: undefined},
                password: {value: '', name: 'password', label: 'CONTRASEÑA', error: undefined},
            }
        };
        this.toggleShow = this.toggleShow.bind(this);

        this.listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              document.getElementById("login").click();
            }
        };
        document.addEventListener("keydown", this.listener);
    }
    componentDidUpdate(prevProps){    

        if(this.state.submitted === true && this.props.loginStatus === "ERROR"){
            this.setState({showSpinner: false})
            let newState = this.state.components;
            //console.log("newState: ", newState)
            newState["username"].error = true
            newState["password"].error = true
            this.setState({components: newState})
            this.setState({submitted: false})
            this.setState({errorMessage: true})
        }

        if(prevProps.loginResponse !== this.props.loginResponse && this.props.loginStatus === 'SUCCESS'){
            console.log("login ok")
             
            document.removeEventListener("keydown", this.listener);
            //let account = this.props.loginResponse
            //account.roles = [];
            //account.token = account.access_token;
            //console.log("account: ", account)
            //Cookies.set('account', JSON.stringify(account).toString())
            this.setState({showSpinner: false})
            //window.parent.postMessage({message: 'login', token: ""},'*')
            this.setCookies(this.props.loginResponse)
                
        }

    }

    setCookies(cookies){

        //console.log("cookies: ", cookies)
        
        let rolesFiltrados
        let rolesCookies = cookies.authorities

        rolesFiltrados = rolesCookies.filter(function(item){
            if(item.authority.startsWith("SHOW_MENU_")){
                return item
            }
        })

        rolesCookies = rolesCookies.filter(function(item){
            if(!item.authority.startsWith("SHOW_MENU_")){
                return item
            }
        })
        cookies.token = cookies.access_token
        cookies.authorities = []
        cookies.roles = []
        cookies.userRoles = rolesCookies;
        cookies.codigoAgente = cookies.agentCode
        cookies.nombre = cookies.agentName

        var expireDate = new Date();
        var time = expireDate.getTime();
        var expireTime = time + COOKIE_EXPIRE_TIME
        expireDate.setTime(expireTime);

        let encoded = encodeURIComponent(JSON.stringify(cookies).replace("%25", "%"))

        Cookies.set('account', JSON.stringify(cookies), {domain: COOKIE_DNS, expires: expireDate});
        Cookies.set('accountSIP', encoded, {domain: COOKIE_DNS, expires: expireDate});

        window.parent.postMessage({message: 'login', token: JSON.stringify(cookies.token)},'*')
        window.parent.postMessage({message: 'menuPermissions', value: JSON.stringify(rolesFiltrados)},'*')
    }

    handleChange(e){
        const target = e.currentTarget;
        let currentState = this.state.components
        currentState[target.name].value = target.value
        currentState["username"].error = false
        currentState["password"].error = false
        this.setState({components: currentState});
    }

    onClickLogin(){
        this.setState({submitted: true, showSpinner: true})
        this.props.login({username: this.state.components.username.value, password: this.state.components.password.value})
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    render() {

        
        
        let errorMessage = <p className="login-error-message">Error de login. Compruebe sus datos e intente de nuevo.</p>

        return (
        <div className="login-container">
            
            <div >
                    <div className="title-container">
                        <div className="logo-container">
                            <a href="https://sep.colonseguros.com.ar"><img className="logoLogin" src={logo} alt="" /></a>
                        </div>
                        <div className="message-portal">
                            <p>Portal de Productores</p>
                        </div>
                    </div>
            </div>
            <div>
            <Grid  textAlign='center' className={"grid-container"} verticalAlign='top'>
                <Grid.Column className={"form-container"} style={{ maxWidth: 700 }}>
                    <img className="imgLogin" src={image} alt="" />
                    <div className="input-container">
                    <div className="subtitle-container">
                        <h3>Iniciar Sesión</h3>
                        <p><label>Bienvenido a nuestro Portal de Productores.</label></p>
                    </div>
                    <Form size='large'>
                    <Segment stacked>
                        <Form.Input 
                        fluid icon='user' 
                        iconPosition={"left"}
                        placeholder='Usuario' 
                        label='USUARIO'
                        onChange={(e)=>this.handleChange(e)} 
                        name={this.state.components.username.name} 
                        error={this.state.components.username.error}
                        />
                        <Form.Input 
                        fluid 
                        placeholder='Contraseña' 
                        label='CONTRASEÑA'
                        type={this.state.hidden ? "password" : "text"}
                        onChange={(e)=>this.handleChange(e)} 
                        name={this.state.components.password.name} 
                        error={this.state.components.username.error}
                        icon={<i className="eye icon icon-show-pass" onClick={()=>this.toggleShow()}/>}
                        />
                        
                        {this.state.errorMessage === true ? errorMessage : null}
                        <a className="link-olvide-password" href="https://seguros.colonseguros.com.ar/ov_consultas/#/recuperarContrasenia">¿Olvidaste tu contraseña?</a>
                        <Button id="login" color='red' fluid size='large' onClick={()=>this.onClickLogin()} type='button'>Ingresar</Button>
                    </Segment>
                    </Form>
                    </div>
                </Grid.Column>
                </Grid>
            </div>

            <div className="ui relaxed grid footer">
                <div className="three column row first-footer">
                    <div className="column footer-left"><a href="https://seguros.colonseguros.com.ar/ov_consultas/#/legales/politicas-privacidad">Política de privacidad del Sitio Web</a></div>
                    <div className="column footer-center"><a href="https://seguros.colonseguros.com.ar/ov_consultas/#/legales/terminosyCondiciones">Términos y Condiciones</a></div>
                    <div className="column footer-right"><a href="https://seguros.colonseguros.com.ar/ov_consultas/#/legales/derecho-acceso-disposicion">Derecho de acceso disposición DNPDP N°10/08</a></div>
                </div>
                <div className="sixteen wide column last-footer">
                    © {new Date().getFullYear()} Colón Seguros - Todos los derechos reservados.
                </div>
            </div>

            {/**SPINNER**/}      
            {<div className={this.state.showSpinner === true ? "show" : "not-show"}>
                <div className={"ui active dimmer page-loading"}>
                    <div className="ui text loader">Por favor espere unos instantes...</div>
                </div>
            </div>}
            {/**SPINNER**/}      
        </div>
        );
      }
}