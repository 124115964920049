import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import AppCoreCnt from '../containers/AppCoreCnt';

const Root = ({ store }) => (
  <Provider store={store}>
    <div>
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={AppCoreCnt} />
      </Switch>
    </BrowserRouter>
    </div>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
