import React, { Component } from 'react'
import { Menu, Input, Button, Form } from 'semantic-ui-react'
import _ from 'lodash'
import  '../styles/main.scss'
import logoImage from '../assets/images/logo_Description10@.jpg';
import logo from "../assets/images/logo-@.png";
import PageEmbeddedCmp from './PageEmbeddedCmp'
import Cookies from 'js-cookie';
import {URL_HOME_DEFAULT, URL_HOME_TELEMARKETING, API_URL_FRONT, AMBIENTE, COOKIE_DNS, COOKIE_EXPIRE_TIME} from '../constants/config'
import * as CONSTANTS from '../constants/constants'

export default class MenuCmp extends Component {

  getUsernameMenu(){
    let account = Cookies.getJSON("account")
    //console.log("menu account", account)
    if(account)
      return account.codigoAgente + ' - ' + account.nombre
    this.props.checkToken()  
    return "";
  }

  getUserPermissions(){
    let account = Cookies.getJSON("account")
    let permissions = []
    //console.log(localStorage.getItem("menuPermissions"))
    if(account){
      //permissions = _.map(account.userRoles, (item)=>{return item.authority})
      permissions = _.map(JSON.parse(localStorage.getItem("menuPermissions")), (item)=>{return item.authority})
    }
    return permissions
  }

  onClickLogout() {
    this.props.logout()
  }

  /*onClickProps(){
    console.log(this.state)
  }*/

  showModalIntermediario(){
    this.setState({showModalIntermediario: true})
  }

  isBackoffice(){
    //console.log(_.find(Cookies.getJSON("account").userRoles, (item)=>{return item.authority === "ROLE_BACKOFFICE"}))
    return _.find(Cookies.getJSON("account").userRoles, (item)=>{return item.authority === CONSTANTS.ROLE_BACKOFFICE}) !== undefined;
  }


  searchRole(role){
    return _.find(Cookies.getJSON("account").userRoles, (item)=>{return item.authority === role}) !== undefined;
  }

  getUserRole(){
    let roleList = [CONSTANTS.ROLE_BACKOFFICE, CONSTANTS.ROLE_TELEMARKETING]
    for(var i = 0; i < roleList.length ; i++){
      let hasRole = this.searchRole(roleList[i])
      if(hasRole === true)
        return roleList[i];
    }
    return undefined
  }

  getHomeByRole(){
    let homes = {
                  [CONSTANTS.ROLE_BACKOFFICE]: URL_HOME_DEFAULT,
                  [CONSTANTS.ROLE_TELEMARKETING]: URL_HOME_TELEMARKETING,
                  undefined: URL_HOME_DEFAULT
                }
    //console.log("return: ", this.getUserRole())
    //console.log("home: ", homes[this.getUserRole()])
    return homes[this.getUserRole()]
  }

  constructor(props){
    super(props)

    let self = this;

    window.parent.onmessage = function(event) {
      //console.log("event menu", event)
      if(event.data.message === "spinner"){
        self.setState({showSpinner: false})
      }    
    };

    
    //let urlHome = URL_HOME
    let urlHome = this.getHomeByRole()
    
    let initialState = {
      showSpinner: false,
      currentURL: urlHome, 
      showModalIntermediario: false,
      openSelectize: false,
      permissions: this.getUserPermissions(),
      isBackoffice: this.isBackoffice()
    }
    this.state = this.generateState(props.menu.menu, this, initialState)
  }



  componentDidUpdate(prevProps){
    this.ifStatusChange(prevProps)    
  }

  componentDidMount() {
    // Cuando el componente se monta revisa si tiene parametros en la url que indiquen que debe ir a una app especifica por medio de su ID en menu_provider
    let parameters = {}
    let paths = window.location.search.split('?')
    if (window.location.search) {
      paths = paths[1].split('&')
      paths.map(val => {
        parameters[val.split('=')[0]] = val.split('=')[1]
      })
      if(parameters.ir) {
        this.handleMenuClick({}, { name: parameters.ir, idCotizacion: parameters.idCotizacion }, this)
      }
    }
    console.log(parameters)
  }

  generarNivel(self, menu, state, nivel){
    _.forEach(menu, (item)=>{
        let submenu 
        let link
        if(item.submenu && item.submenu.length > 0)
          submenu = {display: "not-show"}
        else
          link = item.link
        //console.log("" + item._id  +  item.label)
        
          
        state[item._id] = {className:  "nivel-links-" + nivel , active: "", submenu: submenu, link: link}
        if(item.submenu && item.submenu.length > 0){
          
          self.generarNivel(self, item.submenu, state, nivel+1)
      }
    })
    return state
  }

  generarMenuLogin(self, state){
    state['menu-login'] = {className:  "nivel-login-links-1", active: "", submenu: {display: "not-show"}}
    state['submenu-login'] = {className:  "nivel-login-links-2", active: "", submenu: {display: "not-show"}}
    state['elegir-intermediario'] = {className:  "nivel-login-links-2", active: "", action: { message: "elegir-intermediario", method: "elegirIntermediario" }}
    state['atencion-al-cliente'] = {className:  "nivel-login-links-2", active: "", action: { message: "atencion-al-cliente", method: "atencionAlCliente"}}
    state['salir'] = {className:  "nivel-login-links-2", active: "", action: { message: "logout", method: "exitMethod"}}
    return state
  }

  showMenu(self, state) {
    state['show_menu'] = false
    state['logo'] = true
    return state
  }

  generateState(menu, self, initialState){
    let state = initialState === undefined ? {} : initialState 
    
    state = self.generarNivel(self, menu, state, 1)
    state = self.generarMenuLogin(self, state)
    state = self.showMenu(self, state)
    return state;
  }

  handleMenuClick(event, name, self){
    let targetName = name.name;

    console.log(name)

    //console.log("event", event)
    let newState = Object.assign({}, self.state);
    //console.log("self state", self.state)
    let currentStateTarget=newState[targetName] 
    
    if(currentStateTarget.submenu)
      if(currentStateTarget.submenu && currentStateTarget.submenu.display === "not-show")
        currentStateTarget.submenu.display = "show"
      else 
        currentStateTarget.submenu.display = "not-show"
    else{
      let cadena = currentStateTarget.link
      let url 
      
      let ambiente = AMBIENTE

      if(cadena.startsWith('https://'))
        url = currentStateTarget.link
      else
        url = 'https://sep' + ambiente + '.colonseguros.com.ar' + currentStateTarget.link

      // Se agrega para utilizar en cotizadores  
      if (name.idCotizacion) {
        url = url + '?idCotizacion=' + name.idCotizacion
      }

      console.log(url)
      self.props.checkToken()
      let spin = false
      
      //console.log("url: ", url)        
      if( url.startsWith('https://sep' + ambiente + '.colonseguros.com.ar/gestor-cotizador-emisor/') ||
          url.startsWith('https://sep' + ambiente + '.colonseguros.com.ar/ov_consultas/') || 
          url.startsWith('https://sep' + ambiente + '.colonseguros.com.ar/paquetes/') || 
          //url.startsWith('https://sep' + ambiente + '.colonseguros.com.ar/hogar/') ||
          url.startsWith('https://sep' + ambiente + '.colonseguros.com.ar/vo/')){
        spin = true
      }

      /* DESCOMENTAR SI QUEREMOS QUE EL MENU REDIRECCIONE A UNA URL LOCAL*/
      
      /*let urlSplitteada = url.split('/')   
      let prefixLength = (urlSplitteada[0] + "/" + urlSplitteada[1] + "/" + urlSplitteada[2] +"/"+ urlSplitteada[3] + "/").length
      let urlLocal = 'http://localhost:9001/'
      url = urlLocal + url.substring(prefixLength, url.length)*/

      if (name.idCotizacion) {
        url = url + '?idCotizacion=' + name.idCotizacion
      }
      

      newState = self.generateState(self.props.menu.menu, self)
      newState.currentURL = url;
      newState.showSpinner= spin;
      newState.reload = true;
      setTimeout(()=>self.setReloadFalse(), 1000)
    }
    
    if(currentStateTarget.active === "")
      currentStateTarget.active = "active"
    else 
      currentStateTarget.active = ""
    //console.log("newState", newState)
    self.setState(newState)
  } 

  setReloadFalse(){
    //console.log("setReloadFalse")
    this.setState({reload: false})
  }
  //handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  generateSubMenuSegundoNivel(self, _idParent, submenu, handle, parent){
    let submenues = []
    _.forEach(submenu, function(item){
      let className = self.state[item._id].className + " " + self.state[item._id].active
      if(_.find(self.state.permissions, (elem)=>{return elem === item.permission})!== undefined)
        submenues.push(
        <Menu.Item 
            className={className}
            name={item._id}
            key={item._id}
          onClick={(event, name)=>handle(event, name, self)}
          >{item.label}</Menu.Item>
        )
    })
    return (
      <Menu vertical secondary={true} key={'subsubmenu'+_idParent} className={"tercer-nivel tercer-nivel-"+self.state[parent].submenu.display}>
        {submenues}
      </Menu>)
  }

  generateSubMenu(self,_idParent, submenu, handle, parent){
    let submenues = []
    
    _.forEach(submenu, function(item){
      let subMenuSegundoNivel = []
      if(item.submenu && item.submenu.length > 0){
        subMenuSegundoNivel = self.generateSubMenuSegundoNivel(self, item._id, item.submenu, handle, item._id)
      }
      let className = self.state[item._id].className + " " + self.state[item._id].active
      if(_.find(self.state.permissions, (elem)=>{return elem === item.permission})!== undefined)
        submenues.push(
        <Menu.Item 
            className={className}
            key={item._id}    
            name={item._id}
          onClick={(event, name)=>handle(event, name, self)}
          >{item.label}</Menu.Item>
        ,subMenuSegundoNivel)
    })
    return (
      <Menu vertical secondary={true} key={'submenu'+_idParent} className={"segundo-nivel segundo-nivel-"+self.state[parent].submenu.display} >
        {submenues}
      </Menu>)
  }

  generateMenu(self, handle){
    let menu = this.props.menu.menu;
    let menues = []
    
    _.forEach(menu,function(item){
      let submenu = []
      if(item.submenu && item.submenu.length > 0){
        submenu = self.generateSubMenu(self, item._id, item.submenu, handle, item._id)
      }
      
      let className = self.state[item._id].className + " " + self.state[item._id].active
      if(_.find(self.state.permissions, (elem)=>{return elem === item.permission})!== undefined)
        menues.push(<Menu.Item 
            className={className}
            key={item._id}
            name={item._id}
            onClick={(event, name)=>handle(event, name, self)}>
            {item.label}
            </Menu.Item>
            ,submenu
            )
    })

    return <Menu vertical className="primer-nivel" >
              {menues}
            </Menu>
  }



  atencionAlCliente(self){
    window.location.href = "mailto:ATProductor@colonseguros.com.ar?subject=COLON | Consulta"
  }

  exitMethod(self){
    //console.log("metodo exit method")
    //Cookies.set("tokenMenu", undefined)
    //localStorage.set("token", undefined)
    //Cookies.set("account", undefined)
    self.props.logout()
    Cookies.remove("account")
    Cookies.remove("accountSIP")
    window.location.href = API_URL_FRONT;
  }

  executeAction(self, action){
    window.parent.postMessage({message: action.message},'*')
    self[action.method](self)
  }

  handleMenuLoginClick(event, name, self){
    //console.log(name)
    //let target = event.target
    let targetName = name.name;
    //console.log(self.state)
    let newState = Object.assign({}, self.state);
    let currentStateTarget=newState[targetName] 
    
    if(currentStateTarget.submenu)
      if(currentStateTarget.submenu && currentStateTarget.submenu.display === "not-show")
        currentStateTarget.submenu.display = "show"
      else 
        currentStateTarget.submenu.display = "not-show"
    else{
      //console.log("action: ",currentStateTarget.action)
      self.executeAction(self, currentStateTarget.action);
      newState = self.generateState(self.props.menu.menu, self)
    }
    if(currentStateTarget.active === "")
      currentStateTarget.active = "active"
    else 
      currentStateTarget.active = ""
    
    self.setState(newState)
  }

  generateMenuLogin(self, handle){
    
    let menueslogin = []
    
    let submenueslogin = []
    let classNameSubMenu = self.state['submenu-login'].className + " " + self.state['submenu-login'].active
    submenueslogin.push(<Menu.Item key="2" name={"elegir-intermediario"} className={classNameSubMenu} onClick={(event, name)=>handle(event, name, self)} >{"ELEGIR INTERMEDIARIO"}</Menu.Item>)
    submenueslogin.push(<Menu.Item key="3" name={"atencion-al-cliente"} className={classNameSubMenu} onClick={(event, name)=>handle(event, name, self)} >{"ATENCIÓN AL PRODUCTOR"}</Menu.Item>)    
    submenueslogin.push(<Menu.Item key="4" name={"salir"} className={classNameSubMenu} onClick={(event, name)=>handle(event, name, self)} >{"SALIR"}</Menu.Item>)  
    
    //console.log("classname", "segundo-nivel-login-" + self.state['menu-login'].submenu.display)
    let submenulogin = ( <Menu vertical secondary={true} key={'submenu-login'} 
                        className={"segundo-nivel-login-" + self.state['menu-login'].submenu.display} >
                      {submenueslogin}
                    </Menu>)
    let className = self.state['menu-login'].className + " " + self.state['menu-login'].active
    menueslogin.push(<Menu.Item key="1" name={"menu-login"} className={className} onClick={(event, name)=>handle(event, name, self)}> {this.getUsernameMenu()} </Menu.Item>, submenulogin)
    return <Menu vertical className="login-menu" >
              {menueslogin}
            </Menu>
  }
  changeMenuShow() {
    this.setState({
      show_menu: !this.state.show_menu, logo: !this.state.logo
      })
  }



  elegirIntermediario(self){
    //console.log("metodo elegir intermediario")
    this.setState({showModalIntermediario : true, intermediarioSelected: undefined, intermediarioValue: ''}) 
   }

  /******************SELECTIZE********************/

  cancelModal(){
    this.setState({showModalIntermediario: false})
  }

  confirmModal(){
    this.props.impersonalizar(this.state.intermediarioSelected)
    //this.setState({showModalIntermediario: false})
  }

  openSelectize(){
    this.setState({openSelectize: true, intermediarioValue: ''})
    let lista = []
    let listaIntermediarios = this.props.intermediarios
    listaIntermediarios.forEach((item, index)=>{
      lista.push(<div key={index} onClick={()=>this.seleccionarIntermediario(index, this.props.intermediarios)} className="field-selectize" >{"Tipo Agente: " + item.tipoCodigoYNombre}</div>)
    })
    
    let intermediarios = <div>
      {lista}
    </div>

    this.setState({intermediarios: intermediarios, intermediariosSearched: []});
  }

  seleccionarIntermediario(index, list){
    
    let selected = list[index];
    console.log("selected: ", selected)
    if(selected !== undefined)
    this.setState({intermediarioSelected: selected, openSelectize: false, intermediarioValue: "Tipo Agente:" + selected.tipoCodigoYNombre});
  }

  generarListaIntemediarios(intermediariosSearched){
    this.setState({openSelectize: false, intermediariosSearched: []})
    let lista = []
    console.log("intermediariosSearched: ", intermediariosSearched)
    if(intermediariosSearched.error === "invalid_token"){
      this.props.checkToken()
      alert("Expiró tu sesión o se abrió en otro navegador")
      return;
    }

    intermediariosSearched.forEach((item, index)=>{
      lista.push(<div key={index} onClick={()=>this.seleccionarIntermediario(index, this.props.intermediariosSearch)} className="field-selectize" >{"Tipo Agente: " + item.tipoCodigoYNombre}</div>)
    })

    let intermediarios = <div>
      {lista}
    </div>
    //this.state.intermediariosSearched = []
    this.setState({intermediariosSearched: []})
    this.setState({intermediarios: [], intermediariosSearched: intermediarios, openSelectize: true})
  }

  ifStatusChange(prevProps){
    if(prevProps.intermediariosSearchStatus === 'FETCHING' && this.props.intermediariosSearchStatus ==='SUCCESS'){
      this.generarListaIntemediarios(this.props.intermediariosSearch)
    }
    if(prevProps.impersonalizarStatus === 'FETCHING' && this.props.impersonalizarStatus ==='SUCCESS'){
      // console.log("se impersonalizo", this.props.impersonalizado)
      let impersonalizado = this.props.impersonalizado;
      //window.location.href = API_URL_FRONT
      let cookie = Cookies.getJSON('account')
      //console.log("cookie: ", cookie)
      cookie.codigoAgente = impersonalizado.codigoAgente;
      cookie.nombre = impersonalizado.nombre;
      cookie.tipoAgente = impersonalizado.tipoAgente;
      cookie.isImpersonalized = true;
      cookie.cuitImpersonalized = impersonalizado.cuit;
      Cookies.remove('account', {domain: COOKIE_DNS})
      Cookies.remove('accountSIP', {domain: COOKIE_DNS})
      let encoded = encodeURIComponent(JSON.stringify(cookie).replace("%25", "%"))
      
      var expireDate = new Date();
      var time = expireDate.getTime();
      var expireTime = time + COOKIE_EXPIRE_TIME
      expireDate.setTime(expireTime);

      Cookies.set('account', JSON.stringify(cookie), {domain: COOKIE_DNS, expires: expireDate});
      Cookies.set('accountSIP', encoded, {domain: COOKIE_DNS, expires: expireDate});
      window.location.href = API_URL_FRONT
    }
  }

  buscarIntermediarios(event){
    
    this.setState({intermediarioValue: event.target.value});
    
    if(event.target.value.length < 3 || !this.state.isBackoffice){
      return; 
    }
    //console.log(event.target.value)
    this.props.getIntermediariosSearch({intermediariosSearched: [], search: event.target.value})
  }

  /******************SELECTIZE********************/

  render() {

    // const { activeItem } = this.state
    return (
      <div>
        <div className={"menu-left " + (this.state.show_menu ? "menu-collapse" : "")}>
          
          {/*<Button color='teal' fluid size='large' onClick={()=>this.onClickProps()}>Props</Button>*/}
          {/*this.generateMenu(this, activeItem, this.handleItemClick)*/}
          <div className="container">
          
          <div className="container_logo">
          <button className="button_menu" onClick={()=>this.changeMenuShow()}>
            <i className="bars icon"></i>
          </button>
          <a href={API_URL_FRONT}>
            <img className="logo" src={logo} alt={"logo"} />
          </a>
          </div>
          {this.state.show_menu ? (
          <React.Fragment>
            <a href={API_URL_FRONT}>
              <img className="logo_description" src={logoImage} alt={"logo"} />
            </a>
          {this.generateMenuLogin(this, this.handleMenuLoginClick)}
          {this.generateMenu(this, this.handleMenuClick)}
          
          </React.Fragment>
          ) : null}
          </div>
          {/*<Button color='teal' fluid size='large' onClick={()=>this.showModalIntermediario()}>Props</Button>*/}          
        </div>
        <PageEmbeddedCmp url={this.state.currentURL} openMenu={this.state.show_menu} reload={this.state.reload}/>
        {/**MODAL IMPERSONALIZACION**/}      
        <div className={this.state.showModalIntermediario ? "show" : "not-show"}>
            <div className={"modal-blocker"} onClick={()=>this.cancelModal()}></div>
            <div className={"modal-content"}>
              <div className={"btn-x"} onClick={()=>this.cancelModal()}>X</div>
              <div className={"modal-title"}>Selección de Intermediario</div>
              <div className={"modal-body"}><b>Nombre</b>
              <div>
              <Form className="form-large">
                <Form.Group widths="equal">
                <Form.Field
                control={Input} 
                fluid 
                onClick={()=>this.openSelectize()}
                value={this.state.intermediarioValue}
                onChange={(e)=>this.buscarIntermediarios(e)}
                placeholder="Ingrese un parámetro de búsqueda"
                />
                <div className={"row-container-selectize " + (this.state.openSelectize ? "show": "not-show" )}>
                  {this.state.intermediarios}
                  {this.state.intermediariosSearched}
                </div>
                </Form.Group>
                </Form>                
              </div>
              <div className={"modal-bottom"}>
                <Button color='red' size='small' onClick={()=>this.cancelModal()}>Cancelar</Button>
                <Button color='blue' size='small' onClick={()=>this.confirmModal()}>Confirmar</Button>
              </div>
              </div>
            </div>
        </div>
        {/**MODAL IMPERSONALIZACION**/}      

        {/**SPINNER**/}      
        
        {<div className={this.state.showSpinner === true ? "show" : "not-show"}>
          <div className={"ui active dimmer"}>
            <div className="ui text loader">Cargando página por favor espere unos instantes...</div>
          </div>
          </div>}
        {/**SPINNER**/}      
      </div>
      )
  }
}